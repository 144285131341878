<template>
  <div class="container">
    <son @imgfile="fathfun"></son>
    <drop placeholder="选择" v-bind:stationData="stationData"></drop>
    <drop v-bind:stationData="stationData"></drop>

    <selfin placeholder="一个占位符" @transin="getname"></selfin>
    <selfin @transin="getanother"></selfin>
  </div>
</template>

<script type="text/ecmascript-6">
import son from '../components/uploadimg'
import drop from '../components/dropselect'
import selfin from '../components/inputself'
export default {
  name: '',
  mixins: [],
  components: {
    son,
    drop,
    selfin
  },
  props: {},
  data () {
    return {
    stationData: [
        {name:'北京',station: 'Amedsvsdsvsvri', value:'1'},
        {name:'上海',station: 'Eur', value:'2'},
        {name:'广州',station: 'Ameri', value:'3'},
        {name:'广州',station: 'Autrilia', value:'3'},
        {name:'广州',station: 'Ameri', value:'3'},
        {name:'广州',station: 'ahahah', value:'3'},
        {name:'广州',station: 'Ameri', value:'3'},
        {name:'广州',station: 'Ameri', value:'3'},
      ],
    }
  },
  computed: {},
  watch: {},
  methods: {
    fathfun (params) {
        console.log('接收值', params)
    },
    getname (item) {
        console.log('input传值', item)
    },
    getanother (item) {
        console.log('另一个传值', item)

    }
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
