<template>
  <div id="inputbox" ref="inputbox">
    <span id="star">*</span>
    <input ref="input" type="text" :disabled="isdisabled" :placeholder="placeholder" @focus="onfoucus" @blur="onblur" class="commeninput" @change="transinput" v-model="name">
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {
    placeholder: {
      type: String
    },
    defaultvalue: {
      type: String
    },
    isdisabled: {
      type: Boolean
    }
  },
  data () {
    return {
      name: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    transinput (){
      this.$emit('transin', this.name)
    },
    onfoucus () {
     this.$refs.inputbox.style.border = '1px solid #E02020'
     this.$refs.inputbox.style.borderRadius = '3px'
    },
    onblur () {
      this.$refs.inputbox.style.border = 'none'
    }
  },
  filters: {},
  mounted () {
    if (this.defaultvalue) {
      this.name = this.defaultvalue
      this.$emit('transin', this.name)
    }
  },
  created () { }
}
</script>

<style scoped>
#inputbox {
  /* width: 345px; */
  height: 60px;
  line-height: 60px;
  background-color: #2b323d;
  margin: 0 auto;
  display: flex;
}
#star {
  color: #E02020;
  margin-left: 15px;
}
.commeninput {
  display: block;
  width: 345px;
  height: 58px;
  background-color: #2B323D;
  border: none;
  margin: 0 auto;
  /* // margin-top: 10px; */
  outline: none;
  padding: 0 15px;
  font-family: Avenir;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(255,255,255,.5);
  border-radius: 3px;
}
</style>
